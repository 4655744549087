import React from "react";
import { Tooltip } from "@mui/material";

export const wrapMiddle = (str: string, left = 6, right = 6): string => {
  if (!str) return "";
  if (str.length <= left + right) return str;
  return `${str.slice(0, left)}...${str.slice(str.length - right)}`;
};

const WrappedAddress = ({ address }: { address: string }) => {
  return (
    <Tooltip title={address}>
      <span>{wrapMiddle(address, 4, 4)}</span>
    </Tooltip>
  );
};

export default WrappedAddress;
