import { useWalletConnect } from "./Connections";
import useContracts from "../contracts/Contracts";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import { useStakerInfoReader, useStakerInfoUpdater } from "./StakerInfo";
import { hexaToBigNumber } from "../../utils/Converters";
import { useAllowancesUpdater } from "./Allowances";
import { ContractFunction } from "@ethersproject/contracts";
import { useLoadingIndicatorUpdater } from "./LoadingIndicator";
import { useStakingDetailsUpdater } from "./StakingDetails";
import {
  LatestBlockDetails,
  useDerivedStakingInfoReader,
  useLatestBlockDetailsUpdater,
} from "./LatestBlockDetails";
import DefaultAPIDataValues, {
  APIData,
} from "../../../../common/rest-client/types";
import BigNumber from "bignumber.js";
import { useUnstakeDialog } from "../../stake/UnstakeWarningDialog";
import { hasPassed } from "../../../../common/date/DateFormat";

const useStakingContractMethods = () => {
  const { getSigner, userAddress, getProvider } = useWalletConnect();
  const { getTokenContract, getStakingContract, unmarshalToken } =
    useContracts();
  const setTokenDetails = useStakerInfoUpdater();
  const setAllowance = useAllowancesUpdater();
  const updateLoadingStatus = useLoadingIndicatorUpdater();
  const updateStakingDetails = useStakingDetailsUpdater();
  const latestBlockDetailsUpdater = useLatestBlockDetailsUpdater();
  const { openDialog: openUnstakeDialog, closeDialog: closeUnstakeDialog } =
    useUnstakeDialog();
  const { unLockingTime } = useDerivedStakingInfoReader();
  const { maxBalance } = useStakerInfoReader();

  const handleTransaction = async (
    transaction: ContractFunction | any,
    successMessage: string
  ) => {
    try {
      const trx = await transaction();
      await trx.wait();
      toast.success(successMessage);
    } catch (e) {
      console.log(e);
      handleTransactionError(e);
    }
  };

  const getLatestBlockDetails = async () => {
    latestBlockDetailsUpdater((state) => ({ ...state, isLoading: true }));
    try {
      const latestBlockNumber = await getProvider().getBlockNumber();
      const latestBlockTimestamp = (
        await getProvider().getBlock(latestBlockNumber)
      ).timestamp;
      const oldBlockTimestamp = (
        await getProvider().getBlock(Number(latestBlockNumber) - 30000)
      ).timestamp;
      const blockTime = (latestBlockTimestamp - oldBlockTimestamp) / 30000;
      const latestBlockDetails: APIData<LatestBlockDetails> = {
        ...DefaultAPIDataValues,
        isSuccess: true,
        data: {
          blockTime,
          blockNumber: new BigNumber(latestBlockNumber),
          timestamp: latestBlockTimestamp,
        },
      };
      latestBlockDetailsUpdater(latestBlockDetails);
    } catch (error) {
      latestBlockDetailsUpdater((state) => ({
        ...state,
        hasError: true,
        error,
      }));
    }
  };

  const getAllowance = async () => {
    const stakingContract = getStakingContract(getSigner());
    const tokenContract = getTokenContract(getSigner());

    const allowance = await tokenContract.allowance(
      userAddress,
      stakingContract.address
    );

    const allowanceValue = hexaToBigNumber(allowance, unmarshalToken?.decimals);
    setAllowance(allowanceValue);
  };

  const getTokenBalance = () => {
    const tokenContract = getTokenContract(getSigner());
    tokenContract.balanceOf(userAddress).then((balance: number) =>
      setTokenDetails((state) => ({
        ...state,
        balance: hexaToBigNumber(balance, unmarshalToken?.decimals),
        maxBalance: balance,
        ...unmarshalToken,
      }))
    );
  };

  const getTokenTotalSupply = () => {
    const tokenContract = getTokenContract(getProvider());
    tokenContract.totalSupply().then((totalSupply: number) =>
      setTokenDetails((state) => ({
        ...state,
        totalSupply: hexaToBigNumber(totalSupply, unmarshalToken?.decimals),
      }))
    );
  };

  const getStakerInfo = () => {
    const stakingContract = getStakingContract(getSigner());
    stakingContract.getStakerInfo(userAddress).then(
      ({ exist, unclaimedRewards, totalRewardsClaimed, stakedAmount }: any) =>
        exist &&
        setTokenDetails((state) => ({
          ...state,
          unclaimedRewards: hexaToBigNumber(
            unclaimedRewards,
            unmarshalToken?.decimals
          ),
          totalRewardsClaimed: hexaToBigNumber(
            totalRewardsClaimed,
            unmarshalToken?.decimals
          ),
          stakedAmount: hexaToBigNumber(stakedAmount, unmarshalToken?.decimals),
        }))
    );
  };

  const getStakingDetails = () => {
    const stakingContract = getStakingContract(getProvider());
    stakingContract
      .getDetails()
      .then(
        ({
          stakeToken,
          rewardToken,
          totalRewards,
          totalRewardsDistributed,
          totalFundsStaked,
          startBlock,
          claimStartBlock,
          isPaused,
          unlockingBlock,
          endBlock,
        }: any) => {
          updateStakingDetails({
            isPaused: isPaused,
            rewardToken: rewardToken,
            stakeToken: stakeToken,
            endBlock: hexaToBigNumber(endBlock, 0),
            startBlock: hexaToBigNumber(startBlock, 0),
            unlockingBlock: hexaToBigNumber(unlockingBlock, 0),
            claimStartBlock: hexaToBigNumber(claimStartBlock, 0),
            totalFundsStaked: hexaToBigNumber(
              totalFundsStaked,
              unmarshalToken?.decimals
            ),
            totalRewardsDistributed: hexaToBigNumber(
              totalRewardsDistributed,
              unmarshalToken?.decimals
            ),
            totalRewards: hexaToBigNumber(
              totalRewards,
              unmarshalToken?.decimals
            ),
          });
          updateLoadingStatus((state) => ({
            ...state,
            isLoadingStakingDetails: false,
          }));
        }
      );
  };

  function handleTransactionError(error: any) {
    const errMsg = error.message;
    if (error.code === 4001) {
      toast.warn("User denied the transaction!");
      return;
    }
    console.warn(error);
    toast.error("Transaction Failed! " + (errMsg || error?.error || ""));
  }

  const getApproval = async () => {
    updateLoadingStatus((state) => ({ ...state, isApproving: true }));
    const stakingContract = getStakingContract(getSigner());
    const tokenContract = getTokenContract(getSigner());
    const totalSupply = await tokenContract.totalSupply();

    await handleTransaction(
      () => tokenContract.approve(stakingContract.address, totalSupply),
      "Approved"
    );

    updateLoadingStatus((state) => ({ ...state, isApproving: false }));
    await getAllowance();
  };

  function getAmount(isMax: boolean, amount: number) {
    if (isMax) {
      return ethers.utils.parseUnits(
        isMax ? maxBalance.toString() : amount.toString(),
        0
      );
    }
    return ethers.utils.parseUnits(
      isMax ? maxBalance.toString() : amount.toString(),
      18
    );
  }

  const stake = async (amount: number, isMax: boolean, onClose: () => void) => {
    console.log("Stake", amount, isMax);
    updateLoadingStatus((state) => ({ ...state, isStaking: true }));
    const stakingContract = getStakingContract(getSigner());

    const formattedAmount = getAmount(isMax, amount);
    await handleTransaction(
      () => stakingContract.stake(formattedAmount),
      "Stake completed"
    );

    updateLoadingStatus((state) => ({ ...state, isStaking: false }));
    getStakerInfo();
    getStakingDetails();
    getTokenBalance();
    onClose();
  };

  const climeReward = async (onClose: () => void) => {
    updateLoadingStatus((state) => ({ ...state, isClimbingRewards: true }));
    const stakingContract = getStakingContract(getSigner());
    await handleTransaction(
      () => stakingContract.claimRewards(),
      "Reward claimed successfully"
    );

    updateLoadingStatus((state) => ({ ...state, isClimbingRewards: false }));
    getStakerInfo();
    getTokenBalance();
    onClose();
  };

  async function unstake() {
    updateLoadingStatus((state) => ({ ...state, isUnstaking: true }));
    const stakingContract = getStakingContract(getSigner());
    await handleTransaction(
      () => stakingContract.unstake(),
      "Unstaked successfully"
    );
    updateLoadingStatus((state) => ({ ...state, isUnstaking: false }));
    getStakerInfo();
    getTokenBalance();
    getStakingDetails();
    closeUnstakeDialog();
  }

  const unstakeWithWarning = async () => {
    if (hasPassed(unLockingTime)) {
      await unstake();
      return;
    }
    openUnstakeDialog();
  };
  return {
    stake,
    getApproval,
    climeReward,
    unstake,
    getAllowance,
    getTokenBalance,
    getTokenTotalSupply,
    getStakerInfo,
    getStakingDetails,
    getLatestBlockDetails,
    unstakeWithWarning,
  };
};

export default useStakingContractMethods;
