import { BigNumber } from "bignumber.js";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { BigZero } from "../../../../common/Numbers";

export interface StakingDetails {
  stakeToken: string;
  rewardToken: string;
  startBlock: BigNumber;
  endBlock: BigNumber;
  claimDelay: number;
  lockingPeriod: number;
  totalRewards: BigNumber;
  totalFundsStaked: BigNumber;
  totalRewardsDistributed: BigNumber;
  isPaused: boolean;
}

export const defaultStakingDetails: StakingDetails = {
  stakeToken: "",
  rewardToken: "",
  startBlock: BigZero,
  endBlock: BigZero,
  claimDelay: 0,
  lockingPeriod: 0,
  totalRewards: BigZero,
  totalFundsStaked: BigZero,
  totalRewardsDistributed: BigZero,
  isPaused: false,
};

export const stakingDetailsState = atom<StakingDetails>({
  key: "stakingDetailsStateV2",
  default: defaultStakingDetails,
});

export const useStakingDetailsUpdater = () => {
  return useSetRecoilState(stakingDetailsState);
};

export const useStakingDetailsReader = () => {
  return useRecoilValue(stakingDetailsState);
};
