import { useState } from "react";

const useToggle = (initialState = false) => {
  const [isOpen, setState] = useState(initialState);
  const close = () => setState(false);
  const open = () => setState(true);
  const toggle = () => setState(!isOpen);
  return { isOpen, close, open, toggle };
};

export default useToggle;
