import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import useWalletConnector, { jRpcURL, Wallets } from "./Connectors";
import { toast } from "react-toastify";
import { Web3Provider } from "@ethersproject/providers";
import { ethers } from "ethers";
import { useWalletOptionsDialog } from "../WalletOptionsDialog";

const provider = new ethers.providers.JsonRpcProvider(jRpcURL);

export function useWalletConnect() {
  const { toggleMenu, closeDialog } = useWalletOptionsDialog();
  const {
    setPreferredWallet,
    getPreferredWalletConnector,
    disconnectWallet,
    switchNetwork,
  } = useWalletConnector();
  const { activate, active, account, deactivate, library } =
    useWeb3React<Web3Provider>();

  const getSigner = () => library?.getSigner();
  const getProvider = () => provider;

  const handleConnectionReject = (e: UnsupportedChainIdError) => {
    toast.warn(e.message);
  };

  const tryAutoConnect = () => {
    activateWallet();
  };

  function activateWallet() {
    const preferredWalletConnector = getPreferredWalletConnector();
    if (!preferredWalletConnector) {
      return;
    }
    activate(preferredWalletConnector, undefined, true)
      .then(closeDialog)
      .catch(async (e) => {
        console.warn("Failed to activate wallet", e);
        if (e?.message?.includes("Unsupported")) {
          const switched = await switchNetwork();
          if (switched) {
            activateWallet();
            return;
          }
        }
        handleConnectionReject(e);
        disconnect();
      });
  }

  const connectWallet = (wallet: Wallets) => {
    setPreferredWallet(wallet);
    activateWallet();
  };

  const disconnect = () => {
    deactivate();
    disconnectWallet();
  };

  return {
    openWalletConnectOptions: toggleMenu,
    connectWallet,
    isConnected: active,
    userAddress: account,
    disconnect,
    getSigner,
    tryAutoConnect,
    getProvider,
  };
}
