import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { atom, useRecoilState } from "recoil";
import {
  DialogActions,
  DialogContentText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import useStakingContractMethods from "../wallet/hooks/StakingContractsMethods";
import { useDerivedStakingInfoReader } from "../wallet/hooks/LatestBlockDetails";
import Countdown from "react-countdown";
import fromUnixTime from "date-fns/fromUnixTime";
import Typography from "@mui/material/Typography";
import { LoadingButton } from "@mui/lab";
import { useLoadingIndicatorReader } from "../wallet/hooks/LoadingIndicator";

const IS_UNSTAKE_WARNING_DIALOG_OPEN = "IS_UNSTAKE_WARNING_DIALOG_OPEN";

const userUnstakeDialogOpenState = atom<boolean>({
  key: IS_UNSTAKE_WARNING_DIALOG_OPEN,
  default: false,
});

export const useUnstakeDialog = () => {
  const [isOpen, updateIsOpen] = useRecoilState(userUnstakeDialogOpenState);
  return {
    isOpen,
    openDialog: () => updateIsOpen(true),
    toggleMenu: () => updateIsOpen(!isOpen),
    closeDialog: () => updateIsOpen(false),
  };
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UnstakeWarningDialog = () => {
  const { isOpen, closeDialog } = useUnstakeDialog();
  const { unstake } = useStakingContractMethods();
  const { unLockingTime } = useDerivedStakingInfoReader();
  const { isUnstaking } = useLoadingIndicatorReader();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth={"xs"}
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography textAlign={"center"} color={"red"}>
          Warning!
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography color={"text.primary"} sx={{ opacity: "90%" }}>
            If you unstake before{" "}
            <Typography
              variant={"overline"}
              fontWeight={"bolder"}
              fontSize={"large"}
            >
              <Countdown date={fromUnixTime(unLockingTime)} />
            </Typography>
            , you will not receive any rewards.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant={"outlined"} color={"secondary"} onClick={closeDialog}>
          Cancel
        </Button>
        <LoadingButton
          sx={{ minWidth: 150 }}
          loading={isUnstaking}
          loadingPosition={"end"}
          variant={"contained"}
          onClick={unstake}
        >
          Unstake
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UnstakeWarningDialog;
