import { addMinutes, format, formatDistanceToNow } from "date-fns";
import fromUnixTime from "date-fns/fromUnixTime";
import isBefore from "date-fns/isBefore";

export const timeFromNow = (epochTime: number) => {
  if (typeof epochTime === "number") {
    return formatDistanceToNow(fromUnixTime(epochTime), {
      addSuffix: true,
    });
  }
  return "";
};

export const toUTC = (epochTime: number) => {
  if (typeof epochTime === "number") {
    const dateTime = fromUnixTime(epochTime);
    return format(
      addMinutes(dateTime, dateTime.getTimezoneOffset()),
      "MMM-dd-yyyy hh:mm:ss a"
    );
  }
  return "";
};

export const toTime = (epochTime: number) => {
  if (typeof epochTime === "number") {
    const dateTime = fromUnixTime(epochTime);
    return format(
      addMinutes(dateTime, dateTime.getTimezoneOffset()),
      "hh:mm a"
    );
  }
  return "";
};

export const toDate = (epochTime: number) => {
  if (typeof epochTime === "number") {
    const dateTime = fromUnixTime(epochTime);
    return format(
      addMinutes(dateTime, dateTime.getTimezoneOffset()),
      "dd MMMM"
    );
  }
  return "";
};

export const hasPassed = (epochTime: number) => {
  if (typeof epochTime === "number") {
    const givenTime = fromUnixTime(epochTime);
    return isBefore(givenTime, new Date());
  }
  return false;
};
