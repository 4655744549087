import { atom, useRecoilValue, useSetRecoilState } from "recoil";

interface LoadingIndicator {
  isStaking: boolean;
  isApproving: boolean;
  isUnstaking: boolean;
  isClimbingRewards: boolean;
  isLoadingStakingDetails: boolean;
}

const DefaultLoadingStatus: LoadingIndicator = {
  isApproving: false,
  isStaking: false,
  isUnstaking: false,
  isClimbingRewards: false,
  isLoadingStakingDetails: true,
};

export const loadingIndicatorState = atom<LoadingIndicator>({
  key: "loadingIndicatorStateV2",
  default: DefaultLoadingStatus,
});

export const useLoadingIndicatorUpdater = () => {
  return useSetRecoilState(loadingIndicatorState);
};

export const useLoadingIndicatorReader = () => {
  return useRecoilValue(loadingIndicatorState);
};
