import React from "react";
import { Avatar } from "@mui/material";
import unmarshalLogo from "../../assets/images/marshLogo.svg";
import bnbLogo from "../../assets/images/bnbLogo.svg";
import AvatarGroup from "@mui/material/AvatarGroup";

const LpAvatar = () => {
  return (
    <AvatarGroup max={2} sx={{ zIndex: 2 }}>
      <Avatar
        sx={{ width: 140, height: 140 }}
        alt="Unmarshal"
        src={unmarshalLogo}
      />
      <Avatar
        sx={{ width: 140, height: 140, marginLeft: "-30px" }}
        alt="Binance"
        src={bnbLogo}
      />
    </AvatarGroup>
  );
};

export default LpAvatar;
