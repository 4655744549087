export interface APIRequestStatus {
  isLoading: boolean;
  hasError: boolean;
  isSuccess: boolean;
}

export interface APIData<Data, ErrorType = any> extends APIRequestStatus {
  error: ErrorType;
  data: Data;
  request?: any;
}

export interface Pages {
  pageNo: number;
  totalPages: number;
  pageSize: number;
  totalElements: number;
}

export interface PaginatedResponse<DataType> {
  data: Array<DataType>;
  errors?: any;
  pages: Pages;
}

const DefaultAPIDataValues: APIData<any> = {
  data: null,
  isLoading: false,
  isSuccess: false,
  hasError: false,
  error: null,
};

export const defaultAPIData = <T>(data: T) => {
  return {
    ...DefaultAPIDataValues,
    data,
  };
};

export default DefaultAPIDataValues;
