import { BigNumber } from "bignumber.js";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { Token } from "../contracts/Contracts";

interface StakerInfo extends Token {
  balance: BigNumber;
  maxBalance: number;
  totalSupply: BigNumber;
  unclaimedRewards: BigNumber;
  totalRewardsClaimed: BigNumber;
  stakedAmount: BigNumber;
}

const DefaultStakerInfo: StakerInfo = {
  decimals: 0,
  address: "",
  name: "",
  symbol: "",
  rewardSymbol: "",
  balance: new BigNumber(0),
  maxBalance: 0,
  totalSupply: new BigNumber(0),
  unclaimedRewards: new BigNumber(0),
  totalRewardsClaimed: new BigNumber(0),
  stakedAmount: new BigNumber(0),
};

export const stakerInfoState = atom<StakerInfo>({
  key: "stakerInfoState",
  default: DefaultStakerInfo,
});

export const useStakerInfoUpdater = () => {
  return useSetRecoilState(stakerInfoState);
};

export const useStakerInfoReader = () => {
  return useRecoilValue(stakerInfoState);
};
