import React, { useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import AppBar from "../AppBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import BuyTokenLink from "./bockchain/stake/BuyTokenLink";
import { useWalletConnect } from "./bockchain/wallet/hooks/Connections";
import useStakingContractMethods from "./bockchain/wallet/hooks/StakingContractsMethods";
import StakingCard from "./bockchain/stake/StakingCard";
import ClimeReward from "./bockchain/stake/ClimeReward";
import { formatBigNumber, formatNumber } from "../common/Amount";
import { useAllowancesReader } from "./bockchain/wallet/hooks/Allowances";
import { Skeleton } from "@mui/lab";
import { useDerivedStakingInfoReader } from "./bockchain/wallet/hooks/LatestBlockDetails";
import Countdown from "react-countdown";
import fromUnixTime from "date-fns/fromUnixTime";
import { BigZero } from "../common/Numbers";
import usePriceStoreClient from "../data-fetchers/PriceStoreClient";
import { useStakerInfoReader } from "./bockchain/wallet/hooks/StakerInfo";
import useRewardTokenPriceClient from "../data-fetchers/RewardTokenPriceClient";
import useStakeTokenPriceClient from "../data-fetchers/StakeTokenPriceClient";
import { usePool } from "../pools/Configs";

type ReactNode = string | React.ReactNode;

interface Detail {
  title: string;
  value: ReactNode;
  isLoaded: boolean;
}

interface DetailsCardProps {
  title: ReactNode;
  detailsList: Array<Detail>;
}

const DetailsCard = ({ title, detailsList }: DetailsCardProps) => {
  return (
    <CardContent>
      <Typography gutterBottom variant="h5" fontWeight={"bold"}>
        {title}
      </Typography>
      {detailsList.map((detail) => (
        <Stack
          key={detail.title}
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography
            variant="subtitle1"
            color="text.disabled"
            fontWeight={"500"}
          >
            {detail.title}
          </Typography>
          {detail.isLoaded ? (
            <Typography variant="subtitle1" fontWeight={"bold"}>
              {detail.value}
            </Typography>
          ) : (
            <Skeleton variant="text" animation="pulse" width={80} height={32} />
          )}
        </Stack>
      ))}
    </CardContent>
  );
};

const StakingDetails = () => {
  const { isConnected, userAddress } = useWalletConnect();
  const {
    getAllowance,
    getTokenTotalSupply,
    getTokenBalance,
    getStakerInfo,
    getStakingDetails,
    getLatestBlockDetails,
  } = useStakingContractMethods();

  const { hasAllowance } = useAllowancesReader();
  const { getPriceDetails: getLPTokenPrice } = usePriceStoreClient();
  const { getPriceDetails: getRewardTokenPrice } = useRewardTokenPriceClient();
  const { getPriceDetails: getStakeTokenPrice } = useStakeTokenPriceClient();
  const derivedStakingInfo = useDerivedStakingInfoReader();
  const stakerInfo = useStakerInfoReader();
  const pool = usePool();

  useEffect(() => {
    getLatestBlockDetails();
    getTokenTotalSupply();
    getStakingDetails();
    getLPTokenPrice();
    getStakeTokenPrice(pool.stakingToken);
    getRewardTokenPrice(pool.rewardToken);
    getLPTokenPrice();
    if (isConnected) {
      getStakerInfo();
      getAllowance();
      getTokenBalance();
    }
  }, [isConnected, userAddress]);

  const StakingDetails: DetailsCardProps = {
    title: "Pool Information",
    detailsList: [
      {
        title: "Staking Period",
        value: `${formatNumber(derivedStakingInfo?.durationInDays, 0)} days`,
        isLoaded: derivedStakingInfo.isLoaded,
      },
      {
        title: "Your stake percentage",
        value:
          derivedStakingInfo.totalFundsStaked &&
          !derivedStakingInfo.totalFundsStaked.eq(BigZero)
            ? formatBigNumber(
                stakerInfo?.stakedAmount
                  .dividedBy(derivedStakingInfo?.totalFundsStaked)
                  .multipliedBy(100)
              ) + " %"
            : "TBD",
        isLoaded: derivedStakingInfo.isLoaded,
      },
      {
        title: "Ends in",
        value: <Countdown date={fromUnixTime(derivedStakingInfo.endTime)} />,
        isLoaded: derivedStakingInfo.isLoaded,
      },
      // {
      //   title: "Unlock in",
      //   value: (
      //     <Countdown date={fromUnixTime(derivedStakingInfo.unLockingTime)} />
      //   ),
      //   isLoaded: derivedStakingInfo.isLoaded,
      // },
    ],
  };

  const RewardDetails: DetailsCardProps = {
    title: "Reward Program",
    detailsList: [
      {
        title: "Annual Percentage Rate (APR)",
        value: `${
          derivedStakingInfo.apr.gt(BigZero)
            ? formatBigNumber(derivedStakingInfo.apr) + " %"
            : "TBD"
        }`,
        isLoaded: derivedStakingInfo.isLoaded,
      },
      {
        title: "Total Rewards",
        value: `${formatBigNumber(derivedStakingInfo.totalRewards)} ${
          pool.rewardToken.symbol
        }`,
        isLoaded: derivedStakingInfo.isLoaded,
      },
      {
        title: "Today's Rewards",
        value: `${formatBigNumber(derivedStakingInfo.perDayReward)} ${
          pool.rewardToken.symbol
        }`,
        isLoaded: derivedStakingInfo.isLoaded,
      },
      {
        title: "Total Staked",
        value: `${formatBigNumber(derivedStakingInfo.totalFundsStaked)} ${
          pool.stakingToken.symbol
        }`,
        isLoaded: derivedStakingInfo.isLoaded,
      },
    ],
  };

  return (
    <>
      <Stack>
        <AppBar />
        <Container
          maxWidth={"lg"}
          component={Card}
          variant={"outlined"}
          sx={{
            position: "relative",
            mt: -25,
            py: 3,
            mx: {
              lg: "auto",
              sm: 2,
              xs: 1,
            },
            width: {
              lg: 1,
              xs: "auto   ",
            },
            flex: 1,
            minHeight: "80%",
          }}
        >
          <Stack justifyContent={"center"} spacing={3}>
            <Card variant={"outlined"} sx={{ bgcolor: "#B6ECD2" }}>
              <BuyTokenLink />
            </Card>
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={2}
                justifyContent={"center"}
                alignItems={"stretch"}
              >
                <Grid item xs={12} md={6}>
                  <Card variant={"outlined"}>
                    <DetailsCard {...RewardDetails} />
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card variant={"outlined"}>
                    <DetailsCard {...StakingDetails} />
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card variant={"outlined"}>
                    <CardContent>
                      <StakingCard />
                    </CardContent>
                  </Card>
                </Grid>
                {isConnected && (
                  <Grid item xs={12} md={6}>
                    <Card variant={"outlined"}>
                      <CardContent>
                        <ClimeReward />
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Stack>
        </Container>
      </Stack>
    </>
  );
};

export default StakingDetails;
