import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { SERVICE_BASE_URL } from "../../../Config";

interface RestClientOptions extends AxiosRequestConfig {
  beforeFetch?: () => void;
  onSuccess?: (response: AxiosResponse) => void;
  onError?: (error?: any) => void;
  finally?: () => void;
}

export type GetOptions = RestClientOptions;

export type PostOptions = RestClientOptions;

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const doNothing = () => {};

const exchange = (
  promise: Promise<AxiosResponse>,
  options: RestClientOptions
) => {
  const onError = (error: any) => {
    (options?.onError || doNothing)(error);
  };
  (options?.beforeFetch || doNothing)();
  promise
    .then(options?.onSuccess || doNothing)
    .catch(onError)
    .then(options?.finally || doNothing);
};

axios.defaults.baseURL = SERVICE_BASE_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.put["Content-Type"] = "application/json";

const useRestClient = () => {
  const get = (uri: string, options: GetOptions = {}) => {
    exchange(axios.get(uri, options), options);
  };

  const post = (uri: string, { data = {}, ...options }: PostOptions = {}) => {
    exchange(axios.post(uri, data, options), options);
  };

  const put = (uri: string, { data = {}, ...options }: PostOptions = {}) => {
    exchange(axios.put(uri, data, options), options);
  };

  return { get, post, put };
};

export default useRestClient;
