import React from "react";
import unmarshalLogo from "../../../../assets/images/unmarshalLogoCircle.svg";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import useStakingContractMethods from "../wallet/hooks/StakingContractsMethods";
import { useStakerInfoReader } from "../wallet/hooks/StakerInfo";
import { formatBigNumber } from "../../../common/Amount";
import DialogContent from "@mui/material/DialogContent";
import LoadingButton from "@mui/lab/LoadingButton";
import { BootstrapDialog, CloseButton } from "./StakingCard";
import climeRewardLogo from "../../../../assets/images/climeRewardsLogo.svg";
import useToggle from "../../../common/hooks/toggle";
import { useLoadingIndicatorReader } from "../wallet/hooks/LoadingIndicator";
import { BigZero } from "../../../common/Numbers";
import Box from "@mui/material/Box";
import fromUnixTime from "date-fns/fromUnixTime";
import isBefore from "date-fns/isBefore";
import { useDerivedStakingInfoReader } from "../wallet/hooks/LatestBlockDetails";
import Countdown from "react-countdown";
import Card from "@mui/material/Card";
import { usePool } from "../../../pools/Configs";

interface ClimeRewardDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

function StakingDialog({ onClose, isOpen }: ClimeRewardDialogProps) {
  const loadingIndicator = useLoadingIndicatorReader();
  const { climeReward } = useStakingContractMethods();
  const stakerInfo = useStakerInfoReader();
  const pool = usePool();

  const handleClose = () => {
    onClose();
  };

  return (
    <BootstrapDialog onClose={handleClose} open={isOpen}>
      <CloseButton onClose={handleClose} />
      <DialogContent
        sx={{
          maxWidth: 500,
          minWidth: {
            xs: "auto",
            sm: 300,
          },
        }}
      >
        <Stack
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={2}
        >
          <img src={climeRewardLogo} alt="Clime Reward" width={80} />
          <Typography
            color={"GrayText"}
            variant={"subtitle2"}
            fontWeight={"bold"}
            fontSize={"small"}
          >
            Once claimed, your {formatBigNumber(stakerInfo.unclaimedRewards)}{" "}
            {pool.rewardToken.symbol} will be
            <br />
            displayed in your wallet
          </Typography>
        </Stack>
        <br />
        <LoadingButton
          loading={loadingIndicator.isClimbingRewards}
          variant={"contained"}
          fullWidth
          onClick={() => climeReward(onClose)}
        >
          Claim Rewards
        </LoadingButton>
      </DialogContent>
    </BootstrapDialog>
  );
}

const ClimeReward = () => {
  const {
    open: openDialog,
    isOpen: isDialogOpen,
    close: closeDialog,
  } = useToggle();
  const stakerInfo = useStakerInfoReader();
  const derivedStakingInfo = useDerivedStakingInfoReader();
  const climeStartTime = fromUnixTime(derivedStakingInfo.climeStartTime);
  const isClaimable = isBefore(climeStartTime, new Date());
  const { getStakingDetails } = useStakingContractMethods();
  const pool = usePool();

  return (
    <Stack justifyContent={"center"} alignItems="center" spacing={5}>
      <img src={unmarshalLogo} alt="Unmarshal" width={140} />
      <Stack justifyContent={"space-between"} alignItems="center">
        <Typography variant="h6" fontWeight={"bold"}>
          {`Available: ${formatBigNumber(stakerInfo.unclaimedRewards, 4)} ${
            pool.rewardToken.symbol
          }`}
        </Typography>
        <Typography variant="body2" color="text.disabled">
          {`${formatBigNumber(
            stakerInfo?.totalRewardsClaimed?.plus(stakerInfo.unclaimedRewards),
            4
          )} ${pool.rewardToken.symbol} Earned`}
        </Typography>
      </Stack>
      <Box width={"100%"}>
        <Button
          variant={"outlined"}
          fullWidth
          size={"large"}
          onClick={openDialog}
          disabled={stakerInfo.unclaimedRewards.eq(BigZero) || !isClaimable}
        >
          Claim Reward
        </Button>
        {isClaimable ? (
          <Box height={42} />
        ) : stakerInfo.unclaimedRewards.gt(0) ? (
          <Card
            sx={{
              minHeight: 40,
              mt: 2,
              p: 1,
              bgcolor: "#B6ECD2",
              color: "text.secondary",
            }}
            variant={"outlined"}
          >
            <Stack justifyContent={"center"} alignItems={"center"}>
              <Typography
                color="secondary.main"
                variant={"h6"}
                fontWeight={"bold"}
                fontSize={"small"}
                sx={{ opacity: "70%" }}
              >
                Rewards Claimable in:
              </Typography>
              <Typography color={"secondary.main"} sx={{ opacity: "70%" }}>
                <Countdown
                  date={climeStartTime}
                  onComplete={getStakingDetails}
                />
              </Typography>
            </Stack>
          </Card>
        ) : (
          <Box height={90} />
        )}
      </Box>
      <StakingDialog onClose={closeDialog} isOpen={isDialogOpen} />
    </Stack>
  );
};

export default ClimeReward;
