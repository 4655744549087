import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import usePools from "../pools/Configs";
import unmarshalLogo from "../../assets/images/marshLogo.svg";
import LpAvatar from "../common/LPAvatar";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { toPools } from "../routes/routes";
import { PoolTypes } from "../../Types";

const CompletedPools = () => {
  const { pools } = usePools();
  return (
    <Grid container spacing={2} justifyContent={"space-evenly"}>
      {pools
        .filter((pool) => pool.completed)
        .map((pool) => (
          <Grid
            item
            xs={10}
            md={4}
            key={pool?.contract}
            sx={{ margin: "50px" }}
          >
            <Card variant={"outlined"}>
              <CardContent>
                <Stack
                  justifyContent={"center"}
                  alignItems="center"
                  spacing={6}
                >
                  <Typography
                    variant={"h5"}
                    fontWeight={"bold"}
                    sx={{ opacity: "70%" }}
                  >
                    {pool.name}
                  </Typography>
                  <Typography
                    variant={"h6"}
                    fontWeight={"bolder"}
                    fontSize={"small"}
                    sx={{ opacity: "50%" }}
                  >
                    {pool.description}
                  </Typography>
                  {pool.type === PoolTypes.SINGLE ? (
                    <img src={unmarshalLogo} alt="Unmarshal" width={140} />
                  ) : (
                    <LpAvatar />
                  )}
                  <Button
                    component={Link}
                    to={toPools(pool?.contract)}
                    variant={"contained"}
                    size={"medium"}
                  >
                    Withdraw
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        ))}
    </Grid>
  );
};

export default CompletedPools;
