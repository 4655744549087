import React from "react";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import TabPannel from "./TabPannel";

const ListingContainer = () => {
  return (
    <Container
      maxWidth={"lg"}
      component={Card}
      variant={"outlined"}
      sx={{
        position: "relative",
        mt: -25,
        py: 3,
        mx: {
          lg: "auto",
          sm: 2,
          xs: 1,
        },
        width: {
          lg: 1,
          xs: "auto   ",
        },
        flex: 1,
        minHeight: "80%",
      }}
    >
      <TabPannel />
    </Container>
  );
};

export default ListingContainer;
