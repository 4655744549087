import { BigNumber } from "bignumber.js";
import { atom, selector, useRecoilValue, useSetRecoilState } from "recoil";
import { stakerInfoState } from "./StakerInfo";
import { BigZero } from "../../../../common/Numbers";

interface Allowance {
  hasAllowance: boolean;
  value: BigNumber;
}

const allowanceState = atom<BigNumber>({
  key: "allowanceState",
  default: new BigNumber(0),
});

const derivedAllowanceState = selector<Allowance>({
  key: "derivedAllowanceStateV2",
  get: ({ get }): Allowance => {
    const allowanceValue = get(allowanceState);
    const { balance: tokenBalance } = get(stakerInfoState);
    return {
      hasAllowance:
        allowanceValue.gte(tokenBalance) && allowanceValue.gt(BigZero),
      value: allowanceValue,
    };
  },
});

export const useAllowancesUpdater = () => {
  return useSetRecoilState(allowanceState);
};

export const useAllowancesReader = () => {
  return useRecoilValue(derivedAllowanceState);
};
