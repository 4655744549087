import UnmarshalStakingABI from "../../abis/UnmarshalStaking";
import ERC20ABI from "../../abis/ERC20.abi.json";
import { Contract } from "@ethersproject/contracts";
import { usePool } from "../../../../pools/Configs";

export type Token = {
  address: string;
  name: string;
  symbol: string;
  rewardSymbol: string;
  decimals: number;
};

const useContracts = () => {
  const pool = usePool();
  const getStakingContract = (signer: any) =>
    new Contract(pool.contract, UnmarshalStakingABI, signer);
  const getTokenContract = (signer: any) =>
    new Contract(pool?.stakingToken?.address || "", ERC20ABI, signer);
  return {
    getTokenContract,
    getStakingContract,
    unmarshalToken: pool?.stakingToken,
  };
};

export default useContracts;
