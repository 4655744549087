import { BigNumber } from "bignumber.js";
import { atom, selector, useRecoilValue, useSetRecoilState } from "recoil";
import { APIData, defaultAPIData } from "../../../../common/rest-client/types";
import { BigZero } from "../../../../common/Numbers";
import {
  defaultStakingDetails,
  StakingDetails,
  stakingDetailsState,
} from "./StakingDetails";
import { loadingIndicatorState } from "./LoadingIndicator";
import { rewardTokenPriceAtom } from "../../../../data-fetchers/RewardTokenPriceClient";
import { stakeTokenPriceAtom } from "../../../../data-fetchers/StakeTokenPriceClient";

const SECONDS_PER_DAY = 24 * 60 * 60;
const SECONDS_PER_YEAR = new BigNumber(SECONDS_PER_DAY * 365);

export interface LatestBlockDetails {
  blockNumber: BigNumber;
  timestamp: number;
  blockTime: number;
}

const DefaultLatestBlockDetails: LatestBlockDetails = {
  blockNumber: BigZero,
  timestamp: 0,
  blockTime: 0,
};

const latestBlockDetailsState = atom<APIData<LatestBlockDetails>>({
  key: "latestBlockDetailsState",
  default: defaultAPIData(DefaultLatestBlockDetails),
});

export interface DerivedStakingInfo extends StakingDetails, LatestBlockDetails {
  apr: BigNumber;
  startTime: number;
  endTime: number;
  climeStartTime: number;
  unLockingTime: number;
  durationInDays: number;
  perDayReward: BigNumber;
  isLoaded: boolean;
}

const defaultDerivedStakingInfo: DerivedStakingInfo = {
  ...DefaultLatestBlockDetails,
  ...defaultStakingDetails,
  apr: BigZero,
  startTime: 0,
  endTime: 0,
  climeStartTime: 0,
  unLockingTime: 0,
  durationInDays: 0,
  perDayReward: BigZero,
  isLoaded: false,
};

const getBlockTime = (
  currentBlock: BigNumber,
  futureBlock: BigNumber,
  blockTime: number,
  currentBlockTime: number
) => {
  return (
    currentBlockTime +
    futureBlock.minus(currentBlock).times(blockTime).toNumber()
  );
};

function computeAPR(
  endBlock: BigNumber,
  startBlock: BigNumber,
  blockTime: BigNumber,
  totalFundsStaked: BigNumber,
  totalRewards: BigNumber,
  stakeTokenPrice: BigNumber,
  rewardTokenPrice: BigNumber
) {
  const numberOfBlocks = endBlock.minus(startBlock);
  if (
    numberOfBlocks.isGreaterThan(BigZero) &&
    blockTime.isGreaterThan(BigZero) &&
    totalFundsStaked.isGreaterThan(BigZero) &&
    stakeTokenPrice.isGreaterThan(BigZero) &&
    rewardTokenPrice.isGreaterThan(BigZero)
  ) {
    const perBlockReward = totalRewards.div(numberOfBlocks);
    const perSecReward = perBlockReward.div(blockTime);
    const perYearReward = perSecReward.times(SECONDS_PER_YEAR);
    return perYearReward
      .multipliedBy(rewardTokenPrice)
      .div(totalFundsStaked.multipliedBy(stakeTokenPrice))
      .times(100);
  }
  return BigZero;
}

function getRewardsPerDay(
  endBlock: BigNumber,
  startBlock: BigNumber,
  blockTime: BigNumber,
  totalFundsStaked: BigNumber,
  totalRewards: BigNumber
) {
  const numberOfBlocks = endBlock.minus(startBlock);
  if (
    numberOfBlocks.isGreaterThan(BigZero) &&
    blockTime.isGreaterThan(BigZero) &&
    totalFundsStaked.isGreaterThan(BigZero)
  ) {
    const perBlockReward = totalRewards.div(numberOfBlocks);
    const perSecReward = perBlockReward.div(blockTime);
    return perSecReward.times(SECONDS_PER_DAY);
  }
  return BigZero;
}

const derivedStakingInfoState = selector<DerivedStakingInfo>({
  key: "derivedStakingInfoState",
  get: ({ get }): DerivedStakingInfo => {
    const latestBlockDetails = get(latestBlockDetailsState);
    const stakingDetails = get(stakingDetailsState);
    const loadingIndicator = get(loadingIndicatorState);
    const stakeTokenPrice = get(stakeTokenPriceAtom);
    const rewardTokenPrice = get(rewardTokenPriceAtom);

    if (!latestBlockDetails.isSuccess) {
      return defaultDerivedStakingInfo;
    }

    const { blockTime, blockNumber, timestamp } = latestBlockDetails.data;
    const {
      startBlock,
      endBlock,
      claimStartBlock,
      unlockingBlock,
      totalRewards,
      totalFundsStaked,
    } = stakingDetails;
    const apr =
      stakeTokenPrice.isSuccess && rewardTokenPrice.isSuccess
        ? computeAPR(
            endBlock,
            startBlock,
            new BigNumber(blockTime),
            totalFundsStaked,
            totalRewards,
            new BigNumber(stakeTokenPrice.data),
            new BigNumber(rewardTokenPrice?.data)
          )
        : BigZero;
    const startTime = getBlockTime(
      blockNumber,
      startBlock,
      blockTime,
      timestamp
    );
    const endTime = getBlockTime(blockNumber, endBlock, blockTime, timestamp);
    return {
      ...latestBlockDetails.data,
      ...stakingDetails,
      apr: apr,
      isLoaded:
        latestBlockDetails.isSuccess &&
        !loadingIndicator.isLoadingStakingDetails,
      startTime,
      endTime,
      climeStartTime: getBlockTime(
        blockNumber,
        claimStartBlock,
        blockTime,
        timestamp
      ),
      unLockingTime: getBlockTime(
        blockNumber,
        unlockingBlock,
        blockTime,
        timestamp
      ),
      durationInDays: (endTime - startTime) / SECONDS_PER_DAY,
      perDayReward: getRewardsPerDay(
        endBlock,
        startBlock,
        new BigNumber(blockTime),
        totalFundsStaked,
        totalRewards
      ),
    };
  },
});

export const useLatestBlockDetailsUpdater = () => {
  return useSetRecoilState(latestBlockDetailsState);
};

export const useDerivedStakingInfoReader = () => {
  return useRecoilValue(derivedStakingInfoState);
};
