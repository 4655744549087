import { BigNumber } from "bignumber.js";

export const hexaToBigNumber = (
  tokenBNValue: number,
  decimals: number
): BigNumber => {
  if (tokenBNValue) {
    const tokenBN = new BigNumber(tokenBNValue.toString());
    const decimalsBN = new BigNumber(10 ** decimals);
    const tokenValue = tokenBN.dividedBy(decimalsBN);
    return tokenValue;
  } else {
    return new BigNumber(0);
  }
};
