import React, { PropsWithChildren, ReactNode } from "react";
import { ThemeProvider } from "@mui/material";
import Theme from "../theme/Theme";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AutoWalletConnect from "../stakingWithVariableAPR/bockchain/wallet/AutoWalletConnect";
import { RecoilRoot } from "recoil";
import ErrorBoundary from "../common/ErrorBoundary";
import DialogContainer from "../DialogContainer";
import { POLLING_INTERVAL } from "../stakingWithVariableAPR/bockchain/wallet/hooks/Connectors";
import { BrowserRouter } from "react-router-dom";

function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider);
  library.pollingInterval = POLLING_INTERVAL;
  return library;
}

const Providers = ({ children }: PropsWithChildren<ReactNode>) => {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <RecoilRoot>
          <Web3ReactProvider getLibrary={getLibrary}>
            <ToastContainer />
            <AutoWalletConnect />
            <ThemeProvider theme={Theme}>
              {children}
              <DialogContainer />
            </ThemeProvider>
          </Web3ReactProvider>
        </RecoilRoot>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default Providers;
