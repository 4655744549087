import { pools } from "../../Config";
import { Pool } from "../../Types";
import { useParams } from "react-router-dom";

const usePools = () => {
  const getPoolDetails = (contract: string) => {
    return pools.find((pool) => pool.contract === contract);
  };
  return { pools, getPoolDetails };
};

export const usePool = (): Pool => {
  const { address } = useParams();
  const { getPoolDetails } = usePools();
  return getPoolDetails(address as string) as Pool;
};

export default usePools;
