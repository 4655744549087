import React, { useEffect } from "react";
import { useWalletConnect } from "../../bockchain/wallet/hooks/Connections";
import WrappedAddress from "../../../common/WrappedAddress";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import usePriceDetails from "../../../data-fetchers/PriceDetailsClient";
import MarshTokenPrice from "./MarshTokenPrice";
import WalletOptionsDialog from "./WalletOptionsDialog";

export const wrapMiddle = (str: string, left = 6, right = 6): string => {
  if (!str) return "";
  if (str.length <= left + right) return str;
  return `${str.slice(0, left)}...${str.slice(str.length - right)}`;
};

export default function Wallet() {
  const { isConnected, openWalletConnectOptions, userAddress, disconnect } =
    useWalletConnect();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { price: rewardTokenPrice, getPriceDetails } = usePriceDetails();
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDisconnect = () => {
    handleClose();
    disconnect();
  };
  useEffect(() => {
    getPriceDetails();
  }, []);
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <MarshTokenPrice rewardTokenPrice={rewardTokenPrice} />
      &nbsp;
      {!isConnected && (
        <Button variant={"outlined"} onClick={() => openWalletConnectOptions()}>
          Connect Wallet
        </Button>
      )}
      {isConnected && (
        <Button
          variant={"text"}
          endIcon={<KeyboardArrowDownIcon />}
          onClick={handleClick}
        >
          <WrappedAddress address={userAddress || ""} />
        </Button>
      )}
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleDisconnect} disableRipple>
          <Typography color={"primary.main"}>Disconnect</Typography>
        </MenuItem>
      </Menu>
      <WalletOptionsDialog />
    </div>
  );
}
