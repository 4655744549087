import React, { Fragment } from "react";
import Countdown, { CountdownRenderProps } from "react-countdown";
import StakingDetails from "./StakingDetails";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import useStakingContractMethods from "./bockchain/wallet/hooks/StakingContractsMethods";
import { useDerivedStakingInfoReader } from "./bockchain/wallet/hooks/LatestBlockDetails";
import fromUnixTime from "date-fns/fromUnixTime";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useMediaQuery, useTheme } from "@mui/material";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import countDown from "../../assets/images/countDown.png";
import countDownLp from "../../assets/images/countDownLp.png";
import Footer from "../Footer";
import { usePool } from "../pools/Configs";
import { PoolTypes } from "../../Types";
import { CloseButton } from "./bockchain/stake/StakingCard";
import { useNavigate } from "react-router-dom";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BlurryDialog = styled(Dialog)<DialogProps>(() => ({
  backdropFilter: "blur(5px)",
}));

function CountDownDialog({ completed, formatted }: CountdownRenderProps) {
  const { days, hours, minutes, seconds } = formatted;
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("sm"));
  const pool = usePool();
  const navigate = useNavigate();

  if (isMdDown) {
    return (
      <div>
        <BlurryDialog
          fullWidth
          open={!completed}
          TransitionComponent={Transition}
          keepMounted
          PaperProps={{
            sx: {
              opacity: "90%",
              backgroundImage: `url(${
                pool.type === PoolTypes.LP ? countDownLp : countDown
              })`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              mx: 0,
              flexGrow: 1,
              height: {
                xs: 200,
                md: 400,
              },
            },
          }}
        >
          <CloseButton onClose={() => navigate("/")} />
          <DialogContent>
            <Box
              width={"100%"}
              height={"100%"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"flex-end"}
            >
              <Stack
                direction={"row"}
                justifyContent={"center"}
                alignItems={"flex-end"}
                spacing={isMdDown ? 1 : 2}
                width={"100%"}
              >
                <Stack spacing={0.5} direction={"row"} alignItems={"flex-end"}>
                  <Typography
                    color={"text.secondary"}
                    variant={"h5"}
                    fontWeight={"bold"}
                  >
                    {days}
                  </Typography>
                  <Typography color={"primary"} variant={"subtitle1"}>
                    Days
                  </Typography>
                </Stack>
                <Stack spacing={0.5} direction={"row"} alignItems={"flex-end"}>
                  <Typography
                    color={"text.secondary"}
                    variant={"h5"}
                    fontWeight={"bold"}
                  >
                    {hours}
                  </Typography>
                  <Typography color={"primary"} variant={"subtitle1"}>
                    Hours
                  </Typography>
                </Stack>
                <Stack spacing={0.5} direction={"row"} alignItems={"flex-end"}>
                  <Typography
                    color={"text.secondary"}
                    variant={"h5"}
                    fontWeight={"bold"}
                  >
                    {minutes}
                  </Typography>
                  <Typography color={"primary"} variant={"subtitle1"}>
                    Minutes
                  </Typography>
                </Stack>
                <Stack spacing={0.5} direction={"row"} alignItems={"flex-end"}>
                  <Typography
                    color={"text.secondary"}
                    variant={"h5"}
                    fontWeight={"bold"}
                  >
                    {seconds}
                  </Typography>
                  <Typography color={"primary"} variant={"subtitle1"}>
                    Seconds
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </DialogContent>
        </BlurryDialog>
      </div>
    );
  }
  return (
    <BlurryDialog
      fullWidth
      maxWidth={"md"}
      open={!completed}
      TransitionComponent={Transition}
      keepMounted
      PaperProps={{
        sx: {
          opacity: "90%",
          backgroundImage: `url(${
            pool.type === PoolTypes.LP ? countDownLp : countDown
          })`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        },
      }}
    >
      <DialogContent>
        <CloseButton onClose={() => navigate("/")} />
        <Box
          height={"100%"}
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"flex-end"}
          style={{ paddingBottom: "25px" }}
        >
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"flex-end"}
            spacing={isMdDown ? 1 : 2}
            height={400}
            width={"100%"}
          >
            <Stack direction={"row"} alignItems={"flex-end"}>
              <Typography
                color={"text.secondary"}
                variant={"h1"}
                fontWeight={"bold"}
              >
                {days}
              </Typography>
              <Typography color={"primary"} variant={"h6"}>
                Days
              </Typography>
            </Stack>
            <Stack direction={"row"} alignItems={"flex-end"}>
              <Typography
                color={"text.secondary"}
                variant={"h1"}
                fontWeight={"bold"}
              >
                {hours}
              </Typography>
              <Typography color={"primary"} variant={"h6"}>
                Hours
              </Typography>
            </Stack>
            <Stack direction={"row"} alignItems={"flex-end"}>
              <Typography
                color={"text.secondary"}
                variant={"h1"}
                fontWeight={"bold"}
              >
                {minutes}
              </Typography>
              <Typography color={"primary"} variant={"h6"}>
                Minutes
              </Typography>
            </Stack>
            <Stack direction={"row"} alignItems={"flex-end"}>
              <Typography
                color={"text.secondary"}
                variant={"h1"}
                fontWeight={"bold"}
              >
                {seconds}
              </Typography>
              <Typography color={"primary"} variant={"h6"}>
                Seconds
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </DialogContent>
    </BlurryDialog>
  );
}

const LandingPage = () => {
  const { getStakingDetails } = useStakingContractMethods();

  const derivedStakingInfo = useDerivedStakingInfoReader();

  return (
    <Fragment>
      {derivedStakingInfo?.isLoaded && (
        <Countdown
          onComplete={getStakingDetails}
          date={fromUnixTime(derivedStakingInfo.startTime)}
          renderer={(props) => <CountDownDialog {...props} />}
        />
      )}
      <Stack minHeight={"100vh"} justifyContent={"space-between"} spacing={2}>
        <StakingDetails />
        <Footer />
      </Stack>
    </Fragment>
  );
};

export default LandingPage;
