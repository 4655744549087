import React from "react";
import { Route, Routes } from "react-router-dom";
import Landing from "./listing/Landing";
import { default as StakingWithVariableAPR } from "./stakingWithVariableAPR/LandingPage";
import { default as StakingWithVariableAPRV2 } from "./stakingWithVariableAPRV2/LandingPage";
import { default as StakingWithVariableAPRV3 } from "./stakingWithVariableAPR_V3/LandingPage";
import { usePool } from "./pools/Configs";
import Analytics from "./analytics/Analytics";
import { RecoilRoot } from "recoil";

const Pool = () => {
  const pool = usePool();
  if (!pool) {
    window.location.href = "/";
    return <></>;
  }
  switch (pool.version) {
    case 3: {
      return (
        <RecoilRoot>
          <StakingWithVariableAPRV3 />
        </RecoilRoot>
      );
    }

    case 2: {
      return (
        <RecoilRoot>
          <StakingWithVariableAPRV2 />
        </RecoilRoot>
      );
    }

    default: {
      return (
        <RecoilRoot>
          <StakingWithVariableAPR />
        </RecoilRoot>
      );
    }
  }
};

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/pools/:address" element={<Pool />} />
      <Route path="/analytics/:analyticsId" element={<Analytics />} />
    </Routes>
  );
};

export default AppRouter;
