import React from "react";
import Stack from "@mui/material/Stack";
import unmarshalLogo from "../assets/images/unmarshalLogo.svg";
import { Link, Paper } from "@mui/material";
import Container from "@mui/material/Container";
import Wallet from "./stakingWithVariableAPR/bockchain/wallet/Wallet";

const AppBar = () => {
  return (
    <Paper
      sx={{ bgcolor: "secondary.main", py: 2, minHeight: 300, borderRadius: 0 }}
    >
      <Container maxWidth={"lg"} sx={{ padding: 0 }}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Link href="/">
            <img src={unmarshalLogo} alt="unmarshal logo" />
          </Link>
          <Wallet />
        </Stack>
      </Container>
    </Paper>
  );
};

export default AppBar;
