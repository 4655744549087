import React from "react";
import BigNumber from "bignumber.js";
import { BigZero } from "./Numbers";

const NO_OF_DECIMAL_PLACES = 2;
export const formatBigNumber = (
  value: BigNumber = BigZero,
  decimalPlaces: number = NO_OF_DECIMAL_PLACES
): string => {
  return value.toFormat(decimalPlaces);
};

export const formatNumber = (
  value = 0,
  decimalPlaces: number = NO_OF_DECIMAL_PLACES
): string => {
  return formatBigNumber(new BigNumber(value), decimalPlaces);
};

const Amount = ({ value }: { value: BigNumber }) => {
  return <>{formatBigNumber(value)}</>;
};

export default Amount;
