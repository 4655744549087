import { APIData } from "../../../common/rest-client/types";
import { CoingeckoPrice } from "../../../data-fetchers/PriceDetailsClient";
import marshLogo from "../../../../assets/images/marshLogo.svg";
import Typography from "@mui/material/Typography";
import { formatBigNumber } from "../../../common/Amount";
import { BigNumber } from "bignumber.js";
import { Skeleton } from "@mui/lab";
import React from "react";

interface MarshPrice {
  rewardTokenPrice: APIData<CoingeckoPrice>;
}

export default function MarshTokenPrice({ rewardTokenPrice }: MarshPrice) {
  return (
    <>
      <img
        src={marshLogo}
        alt={"Marsh"}
        width={25}
        style={{ padding: "5px" }}
      />
      {rewardTokenPrice?.isSuccess ? (
        <Typography display="inline" color="white" sx={{ pr: 2 }}>
          {" $ " +
            formatBigNumber(
              new BigNumber(rewardTokenPrice?.data?.unmarshal.usd)
            )}
        </Typography>
      ) : (
        <Skeleton
          variant="text"
          animation="pulse"
          sx={{ backgroundColor: "white" }}
          width={50}
          height={32}
        />
      )}
    </>
  );
}
