import React from "react";
import { PoolTypes } from "../../Types";
import LpAvatar from "./LPAvatar";
import unmarshalLogo from "../../assets/images/unmarshalLogoCircle.svg";

const StakeTokenLogo = ({ type }: { type: PoolTypes }) => {
  if (type === PoolTypes.LP) {
    return <LpAvatar />;
  }
  return <img src={unmarshalLogo} alt="Unmarshal" width={140} />;
};

export default StakeTokenLogo;
