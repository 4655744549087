import { atom, useRecoilState, useResetRecoilState } from "recoil";
import DefaultAPIDataValues, { APIData } from "../common/rest-client/types";
import useGetRequest from "../common/rest-client/get";

export interface Price {
  tokenId: string;
  timestamp: string;
  price: number;
}

export interface Unmarshal {
  usd: number;
}
export interface TokenPrice {
  name: string;
  symbol: string;
  tokenId: string;
  price: number;
  percentage_change: string;
}

export const lpTokenPriceAtom = atom<APIData<TokenPrice>>({
  key: "PRICE_STORE_PRICE_DETAILS",
  default: DefaultAPIDataValues,
});

const usePriceStoreClient = () => {
  const [price, updatePriceDetails] = useRecoilState(lpTokenPriceAtom);
  const reset = useResetRecoilState(lpTokenPriceAtom);
  const get = useGetRequest<TokenPrice>();

  const getPriceDetails = () =>
    get(
      updatePriceDetails,
      "/v1/pricestore/chain/bsc/lptokens?lptokens=0x222F93187f15F354D41Ff6a7703eF7e18cdD5103",
      {
        parse: (data: Array<TokenPrice>) => data?.length === 1 && data[0],
      }
    );

  return { price, getPriceDetails, reset };
};

export default usePriceStoreClient;
