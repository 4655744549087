import { EarningTypes, Pool, PoolTypes, TokenTypes } from "./Types";

const tokens = {
  "0x2fa5daf6fe0708fbd63b1a7d1592577284f52256": {
    address: "0x2fa5daf6fe0708fbd63b1a7d1592577284f52256",
    name: "Unmarshal",
    symbol: "MARSH",
    decimals: 18,
    type: TokenTypes.DEFAULT,
    buyLink:
      "https://pancakeswap.finance/swap?outputCurrency=0x2FA5dAF6Fe0708fBD63b1A7D1592577284f52256",
    buyDescription: "Buy Marsh",
  },
  "0x222F93187f15F354D41Ff6a7703eF7e18cdD5103": {
    address: "0x222F93187f15F354D41Ff6a7703eF7e18cdD5103",
    name: "PancakeSwap MARSH-BNB LP Token",
    symbol: "MARSH-BNB",
    decimals: 18,
    type: TokenTypes.LP,
    buyLink:
      "https://pancakeswap.finance/add/0x2FA5dAF6Fe0708fBD63b1A7D1592577284f52256/BNB",
    buyDescription: "Provide MARSH-BNB liquidity",
  },
};

const mainNetPools: Array<Pool> = [
  {
    contract: "0xA6BE3434718DdADe00F64BB768172AA58Db481f7",
    description: "Stake MARSH, get MARSH",
    name: "MARSH Pool",
    type: PoolTypes.SINGLE,
    stakingToken: tokens["0x2fa5daf6fe0708fbd63b1a7d1592577284f52256"],
    rewardToken: tokens["0x2fa5daf6fe0708fbd63b1a7d1592577284f52256"],
    completed: false,
    earningType: EarningTypes.VARIABLE,
    version: 3,
    abiVersion: "v3",
    analyticsDashboardId: "",
  },
  {
    contract: "0x86857D0f2B14ADbdCE3a0Bc27F91725e25Cd52E2",
    description: "Stake MARSH, get MARSH",
    name: "MARSH Pool",
    type: PoolTypes.SINGLE,
    stakingToken: tokens["0x2fa5daf6fe0708fbd63b1a7d1592577284f52256"],
    rewardToken: tokens["0x2fa5daf6fe0708fbd63b1a7d1592577284f52256"],
    completed: false,
    earningType: EarningTypes.VARIABLE,
    version: 3,
    abiVersion: "v3",
    analyticsDashboardId: "",
  },
  {
    contract: "0x869C1A42049B5F3dede13CC3800BBa48A680B5e9",
    description: "Stake MARSH, get MARSH",
    name: "MARSH Pool",
    type: PoolTypes.SINGLE,
    stakingToken: tokens["0x2fa5daf6fe0708fbd63b1a7d1592577284f52256"],
    rewardToken: tokens["0x2fa5daf6fe0708fbd63b1a7d1592577284f52256"],
    completed: true,
    earningType: EarningTypes.VARIABLE,
    version: 3,
    abiVersion: "v3",
    analyticsDashboardId: "",
  },
  {
    contract: "0xbd2867fdD9Fa6464F17e0CaEc01E1aD9D47eC895",
    description: "Stake MARSH-BNB, get MARSH",
    name: "MARSH-BNB Pool",
    type: PoolTypes.LP,
    stakingToken: tokens["0x222F93187f15F354D41Ff6a7703eF7e18cdD5103"],
    rewardToken: tokens["0x2fa5daf6fe0708fbd63b1a7d1592577284f52256"],
    completed: true,
    earningType: EarningTypes.VARIABLE,
    version: 2,
    abiVersion: "v3",
    analyticsDashboardId: "",
  },
  {
    contract: "0xb954D485C329Bd0FbFc881aE19F23b12CD3fdaB2",
    description: "Stake MARSH, get MARSH",
    name: "MARSH Pool",
    type: PoolTypes.SINGLE,
    stakingToken: tokens["0x2fa5daf6fe0708fbd63b1a7d1592577284f52256"],
    rewardToken: tokens["0x2fa5daf6fe0708fbd63b1a7d1592577284f52256"],
    completed: true,
    earningType: EarningTypes.VARIABLE,
    version: 2,
    abiVersion: "v2",
    analyticsDashboardId: "493a7f3a-c151-47db-92ca-4fbe8dd7e4e5",
  },
  {
    contract: "0x9e34459c8c3141E7a71BCa2C00cCbe6d04B7b8c3",
    description: "Stake MARSH, get MARSH",
    name: "MARSH Pool",
    type: PoolTypes.SINGLE,
    stakingToken: tokens["0x2fa5daf6fe0708fbd63b1a7d1592577284f52256"],
    rewardToken: tokens["0x2fa5daf6fe0708fbd63b1a7d1592577284f52256"],
    completed: true,
    earningType: EarningTypes.VARIABLE,
    version: 1,
    abiVersion: "",
    analyticsDashboardId: "6fab5472-b8c8-4ada-8bda-c7f807057d2f",
  },
  {
    contract: "0x6A89EFAAf9c2988717109Db19bd95C4d6861b9F4",
    description: "Stake MARSH-BNB, get MARSH",
    name: "MARSH-BNB Pool",
    type: PoolTypes.LP,
    stakingToken: tokens["0x222F93187f15F354D41Ff6a7703eF7e18cdD5103"],
    rewardToken: tokens["0x2fa5daf6fe0708fbd63b1a7d1592577284f52256"],
    completed: true,
    earningType: EarningTypes.VARIABLE,
    version: 1,
    abiVersion: "",
    analyticsDashboardId: "43233181-a08b-471a-befc-92a41c4b0fc0",
  },
  {
    contract: "0x1de0545e76a6673bEE292add4ef4DbA249351bB0",
    description: "Stake MARSH, get MARSH",
    name: "MARSH Pool",
    type: PoolTypes.SINGLE,
    stakingToken: tokens["0x2fa5daf6fe0708fbd63b1a7d1592577284f52256"],
    rewardToken: tokens["0x2fa5daf6fe0708fbd63b1a7d1592577284f52256"],
    completed: true,
    earningType: EarningTypes.VARIABLE,
    version: 1,
    abiVersion: "",
    analyticsDashboardId: "0d89c5c0-66b6-474c-81f9-b301179e5137",
  },
];

export default mainNetPools;
