const UnmarshalStakingABI = [
  "event AdminAdded(address[] accounts)",
  "event AdminRemoved(address[] accounts)",
  "event OwnershipTransferred(address indexed previousOwner, address indexed newOwner)",
  "event RewardClaimed(address indexed staker, uint256 amount)",
  "event Staked(address indexed staker, uint256 stakedAmount)",
  "event TokensRecovered(address indexed account, address indexed to, uint256 amount)",
  "event Unstaked(address indexed staker, uint256 stakedAmount)",
  "function addAdmin(address[] accounts_)",
  "function admins(address) view returns (bool)",
  "function claimRewards()",
  "function closeStaking()",
  "function getAllStakers() view returns (address[])",
  "function getDetails() view returns (address stakeToken, address rewardToken, uint256 startBlock, uint256 endBlock, uint256 claimStartBlock, uint256 unlockingBlock, uint256 totalRewards, uint256 totalFundsStaked, uint256 totalRewardsDistributed, bool isPaused)",
  "function getStakerInfo(address staker_) view returns (uint256 stakedAmount, uint256 unclaimedRewards, uint256 totalRewardsClaimed, bool exist)",
  "function getUnclaimedRewardsFor(address staker_) view returns (uint256)",
  "function init(address owner_, address stakeToken_, address rewardToken_, uint256 startAfter_, uint256 runningPeriod_, uint256 claimDelay_, uint256 lockingPeriod_, uint256 totalRewards_)",
  "function initOwner(address sender_)",
  "function owner() view returns (address)",
  "function recoverTokens(address token_, address to_, uint256 amount_)",
  "function removeAdmin(address[] accounts_)",
  "function stake(uint256 amount_)",
  "function transferOwnership(address newOwner_)",
  "function unstake()",
  "function updateClaimDelay(uint256 claimDelay_)",
  "function updateConfig(uint256 startAfter_, uint256 runningPeriod_, uint256 claimDelay_, uint256 lockingPeriod_, uint256 totalRewards_)",
  "function updateLockingPeriod(uint256 lockingPeriod_)",
  "function updateStakingStatus()",
];

export default UnmarshalStakingABI;
