import React from "react";
import Stack from "@mui/material/Stack";
import { Link } from "@mui/material";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Instagram, Twitter } from "@mui/icons-material";

const Footer = () => {
  return (
    <Stack bgcolor={"secondary.main"}>
      <Container maxWidth={false} style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography color={"text.secondary"} sx={{ padding: 2 }}>
            <Typography>
              Developed with{" "}
              <Link href="https://www.propel.xyz/" target={"_blank"}>
                Propel
              </Link>
            </Typography>
            <Typography>
              Audited by{" "}
              <Link href="https://techrate.org/" target={"_blank"}>
                TechRate
              </Link>
            </Typography>
          </Typography>
          <Typography sx={{ padding: 2 }}>
            <Link
              href="https://www.instagram.com/unmarshal_io/"
              target={"_blank"}
              sx={{ padding: 2 }}
            >
              <Instagram color={"primary"} />
            </Link>
            <Link
              href="https://twitter.com/unmarshal"
              target={"_blank"}
              sx={{ padding: 2 }}
            >
              <Twitter color={"primary"} />
            </Link>
          </Typography>
        </Stack>
      </Container>
    </Stack>
  );
};

export default Footer;
