import React from "react";
import Providers from "./components/Providers/Providers";
import AppRouter from "./components/AppRouter";

function App() {
  return (
    <Providers>
      <AppRouter />
    </Providers>
  );
}

export default App;
