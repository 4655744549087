import React from "react";
import { useParams } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "@mui/material";
import unmarshalLogo from "../../assets/images/unmarshalLogo.svg";

function AnalyticsAppBar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color={"secondary"}>
        <Toolbar>
          <Link href="/">
            <img src={unmarshalLogo} alt="unmarshal logo" />
          </Link>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

const Analytics = () => {
  const { analyticsId } = useParams();

  if (!analyticsId) {
    console.log("Navigating");
    window.location.href = "/";
  }

  return (
    <div>
      <AnalyticsAppBar />
      <iframe
        name="analytics"
        src={"https://analytics.unmarshal.com/public/dashboard/" + analyticsId}
        style={{
          width: "100vw",
          minHeight: "100vh",
        }}
        frameBorder="0"
        allowTransparency
      />
    </div>
  );
};

export default Analytics;
