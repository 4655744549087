export enum PoolTypes {
  SINGLE = "SINGLE",
  LP = "LP",
}

export enum TokenTypes {
  DEFAULT = "DEFAULT",
  LP = "LP",
}

export enum EarningTypes {
  FIXED = "FIXED",
  VARIABLE = "VARIABLE",
}

export interface Token {
  address: string;
  name: string;
  symbol: string;
  decimals: number;
  type: TokenTypes;
  buyLink: string;
  buyDescription: string;
}

export interface Pool {
  contract: string;
  description: string;
  name: string;
  type: PoolTypes;
  stakingToken: Token;
  rewardToken: Token;
  completed: boolean;
  earningType: EarningTypes;
  version: number;
  abiVersion: string;
  analyticsDashboardId: string;
}
