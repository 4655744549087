import React from "react";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AnalyticsLink from "../../../analytics/AnalyticsLink";
import { usePool } from "../../../pools/Configs";

const BuyTokenLink = () => {
  const pool = usePool();
  return (
    <CardContent>
      <Box>
        <Grid
          container
          spacing={2}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item xs={12} sm={6}>
            <Typography
              sx={{ height: "100%", m: 0 }}
              gutterBottom
              variant={"h6"}
              color={"text.primary"}
              fontWeight={"bold"}
              textAlign={"left"}
            >
              {pool.stakingToken.symbol} Staking
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              textAlign: {
                xs: "center",
                sm: "right",
              },
              alignItems: "center",
            }}
          >
            <Button
              sx={{ mr: 2 }}
              target="_blank"
              href={pool.stakingToken.buyLink || ""}
              variant={"contained"}
              color={"secondary"}
            >
              {pool.stakingToken.buyDescription}
            </Button>
            {/*<AnalyticsLink id={pool.analyticsDashboardId} />*/}
          </Grid>
        </Grid>
      </Box>
    </CardContent>
  );
};

export default BuyTokenLink;
