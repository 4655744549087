import { atom, useRecoilState, useResetRecoilState } from "recoil";
import DefaultAPIDataValues, { APIData } from "../common/rest-client/types";
import useGetRequest from "../common/rest-client/get";

export interface Price {
  tokenId: string;
  timestamp: string;
  price: number;
}

export interface Binancecoin {
  usd: number;
}

export interface Unmarshal {
  usd: number;
}

export interface CoingeckoPrice {
  binancecoin: Binancecoin;
  unmarshal: Unmarshal;
}

export const rewardTokenPriceAtom = atom<APIData<CoingeckoPrice>>({
  key: "PRICE_DETAILS",
  default: DefaultAPIDataValues,
});

const usePriceDetails = () => {
  const [price, updatePriceDetails] = useRecoilState(rewardTokenPriceAtom);
  const reset = useResetRecoilState(rewardTokenPriceAtom);
  const get = useGetRequest<CoingeckoPrice>();

  const getPriceDetails = () =>
    get(
      updatePriceDetails,
      "https://api.coingecko.com/api/v3/simple/price?ids=binancecoin%2Cunmarshal&vs_currencies=usd"
    );

  return { price, getPriceDetails, reset };
};

export default usePriceDetails;
