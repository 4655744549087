import { EarningTypes, Pool, PoolTypes, TokenTypes } from "./Types";

const testNetTokens = {
  "0x49aa0c093754badb728ac804ae08ed176cd04e57": {
    address: "0x49aa0c093754badb728ac804ae08ed176cd04e57",
    name: "Unmarshal",
    symbol: "MARSH",
    decimals: 18,
    type: TokenTypes.DEFAULT,
    buyLink:
      "https://pancakeswap.finance/swap?outputCurrency=0x2FA5dAF6Fe0708fBD63b1A7D1592577284f52256",
    buyDescription: "Buy Marsh",
  },
  "0x8aba917cb3ddbb82189d06dcfe6bbf1f3f47b1dc": {
    address: "0x8aba917cb3ddbb82189d06dcfe6bbf1f3f47b1dc",
    name: "PancakeSwap MARSH-BNB LP Token",
    symbol: "MARSH-BNB",
    decimals: 18,
    type: TokenTypes.LP,
    buyLink:
      "https://pancakeswap.finance/add/0x2FA5dAF6Fe0708fBD63b1A7D1592577284f52256/BNB",
    buyDescription: "Provide MARSH-BNB liquidity",
  },
};

const testNetPools: Array<Pool> = [
  {
    contract: "0x35590601cc2b8D243a6980C21a606A85573528f5",
    description: "Stake MARSH, get MARSH",
    name: "MARSH Pool",
    type: PoolTypes.SINGLE,
    stakingToken: testNetTokens["0x49aa0c093754badb728ac804ae08ed176cd04e57"],
    rewardToken: testNetTokens["0x49aa0c093754badb728ac804ae08ed176cd04e57"],
    completed: false,
    earningType: EarningTypes.VARIABLE,
    version: 3,
    abiVersion: "v3",
    analyticsDashboardId: "493a7f3a-c151-47db-92ca-4fbe8dd7e4e5",
  },
  {
    contract: "0xf6A00bCc8598c64d17Bf69B18e1e94d422487679",
    description: "Stake MARSH, get MARSH",
    name: "MARSH Pool",
    type: PoolTypes.SINGLE,
    stakingToken: testNetTokens["0x49aa0c093754badb728ac804ae08ed176cd04e57"],
    rewardToken: testNetTokens["0x49aa0c093754badb728ac804ae08ed176cd04e57"],
    completed: true,
    earningType: EarningTypes.VARIABLE,
    version: 3,
    abiVersion: "v3",
    analyticsDashboardId: "493a7f3a-c151-47db-92ca-4fbe8dd7e4e5",
  },
  {
    contract: "0xe51906d61F4ea4Ac97cB97684A3979D7f07EbE8e",
    description: "Stake MARSH, get MARSH",
    name: "MARSH Pool",
    type: PoolTypes.SINGLE,
    stakingToken: testNetTokens["0x49aa0c093754badb728ac804ae08ed176cd04e57"],
    rewardToken: testNetTokens["0x49aa0c093754badb728ac804ae08ed176cd04e57"],
    completed: true,
    earningType: EarningTypes.VARIABLE,
    version: 3,
    abiVersion: "v3",
    analyticsDashboardId: "493a7f3a-c151-47db-92ca-4fbe8dd7e4e5",
  },
  {
    contract: "0x141767762B927Cd404Ba14b32A92F149B10cd45E",
    description: "Stake MARSH, get MARSH",
    name: "MARSH Pool",
    type: PoolTypes.SINGLE,
    stakingToken: testNetTokens["0x49aa0c093754badb728ac804ae08ed176cd04e57"],
    rewardToken: testNetTokens["0x49aa0c093754badb728ac804ae08ed176cd04e57"],
    completed: true,
    earningType: EarningTypes.VARIABLE,
    version: 3,
    abiVersion: "v3",
    analyticsDashboardId: "493a7f3a-c151-47db-92ca-4fbe8dd7e4e5",
  },
  {
    contract: "0x3DB712F561f2d54BFd79f0ff5c5e2d42ffa469AA",
    description: "Stake MARSH, get MARSH",
    name: "MARSH Pool",
    type: PoolTypes.SINGLE,
    stakingToken: testNetTokens["0x49aa0c093754badb728ac804ae08ed176cd04e57"],
    rewardToken: testNetTokens["0x49aa0c093754badb728ac804ae08ed176cd04e57"],
    completed: true,
    earningType: EarningTypes.VARIABLE,
    version: 2,
    abiVersion: "v2",
    analyticsDashboardId: "493a7f3a-c151-47db-92ca-4fbe8dd7e4e5",
  },
  {
    contract: "0x6d1EFc6eC96Fad782EdCF6ACBE57fb619c3B67EC",
    description: "Stake MARSH, get MARSH",
    name: "MARSH Pool",
    type: PoolTypes.LP,
    stakingToken: testNetTokens["0x8aba917cb3ddbb82189d06dcfe6bbf1f3f47b1dc"],
    rewardToken: testNetTokens["0x49aa0c093754badb728ac804ae08ed176cd04e57"],
    completed: true,
    earningType: EarningTypes.VARIABLE,
    version: 2,
    abiVersion: "v3",
    analyticsDashboardId: "493a7f3a-c151-47db-92ca-4fbe8dd7e4e5",
  },
  {
    contract: "0x8Ca76745441077ef2293Fd9820ddBb0123748836",
    description: "Stake MARSH, get MARSH",
    name: "MARSH Pool",
    type: PoolTypes.SINGLE,
    stakingToken: testNetTokens["0x49aa0c093754badb728ac804ae08ed176cd04e57"],
    rewardToken: testNetTokens["0x49aa0c093754badb728ac804ae08ed176cd04e57"],
    completed: true,
    earningType: EarningTypes.VARIABLE,
    version: 1,
    abiVersion: "",
    analyticsDashboardId: "493a7f3a-c151-47db-92ca-4fbe8dd7e4e5",
  },
  {
    contract: "0x2b8b9717eE222A9608dbb1Bf50ae0ee271daF06B",
    description: "Stake MARSH-BNB, get MARSH",
    name: "MARSH-BNB Pool",
    type: PoolTypes.LP,
    stakingToken: testNetTokens["0x8aba917cb3ddbb82189d06dcfe6bbf1f3f47b1dc"],
    rewardToken: testNetTokens["0x49aa0c093754badb728ac804ae08ed176cd04e57"],
    completed: true,
    earningType: EarningTypes.VARIABLE,
    version: 1,
    abiVersion: "",
    analyticsDashboardId: "493a7f3a-c151-47db-92ca-4fbe8dd7e4e5",
  },
  {
    contract: "0xE6e4ce224e42182331346A0Abd8973a0Fd933bFa",
    description: "Stake MARSH, get MARSH",
    name: "MARSH Pool",
    type: PoolTypes.SINGLE,
    stakingToken: testNetTokens["0x49aa0c093754badb728ac804ae08ed176cd04e57"],
    rewardToken: testNetTokens["0x49aa0c093754badb728ac804ae08ed176cd04e57"],
    completed: true,
    earningType: EarningTypes.VARIABLE,
    version: 1,
    abiVersion: "",
    analyticsDashboardId: "493a7f3a-c151-47db-92ca-4fbe8dd7e4e5",
  },
];

export default testNetPools;
