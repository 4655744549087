import { InjectedConnector } from "@web3-react/injected-connector";
import { BscConnector } from "@binance-chain/bsc-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { ethers } from "ethers";

const USER_PREFERRED_WALLET = "USER_PREFERRED_WALLET";

const chainIdInString = process.env.REACT_APP_SUPPORTED_CHAIN as string;
export const chainId = Number(chainIdInString);
const supportedChainIds = [chainId];
export const jRpcURL = process.env.REACT_APP_NETWORK_JRPC_URL as string;

const bscConnector = new BscConnector({ supportedChainIds });

export const metamaskConnector = new InjectedConnector({ supportedChainIds });

export const POLLING_INTERVAL = 9000;

export const walletConnector = new WalletConnectConnector({
  rpc: { [chainId]: jRpcURL },
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
});

export enum Wallets {
  Metamask = "METAMASK",
  BscChain = "BSC_CHAIN",
  WalletConnect = "WALLET_CONNECT",
}

const WalletsConnectors: Map<string, any> = new Map<string, any>([
  [Wallets.Metamask, metamaskConnector],
  [Wallets.BscChain, bscConnector],
  [Wallets.WalletConnect, walletConnector],
]);

declare global {
  interface Window {
    ethereum: any;
    BinanceChain: any;
  }
}

const WalletsProviders: Map<string, () => any> = new Map<string, () => any>([
  [Wallets.Metamask, () => window?.ethereum],
  [Wallets.BscChain, () => window?.BinanceChain],
  [Wallets.WalletConnect, () => undefined],
]);

export const switchProviderNetwork = async (wallet: Wallets) => {
  const getProvider = WalletsProviders.get(wallet);
  const provider = getProvider ? getProvider() : undefined;
  if (provider) {
    try {
      provider.request &&
        (await provider.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: ethers.utils.hexlify(chainId),
              chainName: "Binance Smart Chain Mainnet",
              nativeCurrency: {
                name: "BNB",
                symbol: "bnb",
                decimals: 18,
              },
              rpcUrls: [jRpcURL],
              blockExplorerUrls: ["https://bscscan.com"],
            },
          ],
        }));
      return true;
    } catch (error) {
      console.error("Failed to setup the network in Metamask:", error);
      return false;
    }
  } else {
    console.error(
      "Can't setup the BSC network on metamask because wallet provider is undefined"
    );
    return false;
  }
};

const useWalletConnector = () => {
  const getPreferredWalletConnector = () => {
    const preferredWallet: any = localStorage.getItem(USER_PREFERRED_WALLET);
    return WalletsConnectors.get(preferredWallet);
  };

  const setPreferredWallet = (wallet: Wallets) => {
    localStorage.setItem(USER_PREFERRED_WALLET, wallet);
  };

  const handleWalletConnectionReject = () => {
    if (localStorage.getItem(USER_PREFERRED_WALLET) === Wallets.WalletConnect) {
      window.location.reload();
    }
  };

  const clearPreferredWallet = () => {
    localStorage.clear();
  };

  const switchNetwork = () => {
    const wallet: any = localStorage.getItem(USER_PREFERRED_WALLET);
    return switchProviderNetwork(wallet);
  };

  const disconnectWallet = () => {
    const preferredWalletConnector = getPreferredWalletConnector();
    preferredWalletConnector.deactivate();
    getPreferredWalletConnector()?.close &&
      getPreferredWalletConnector()?.close();
    if (preferredWalletConnector instanceof WalletConnectConnector) {
      walletConnector.walletConnectProvider = null;
    }
    clearPreferredWallet();
  };

  return {
    setPreferredWallet,
    getPreferredWalletConnector,
    handleWalletConnectionReject,
    clearPreferredWallet,
    switchNetwork,
    disconnectWallet,
  };
};

export default useWalletConnector;
