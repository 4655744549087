import React, { useState } from "react";
import unmarshalLogo from "../../../../assets/images/unmarshalLogoCircle.svg";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useWalletConnect } from "../wallet/hooks/Connections";
import useStakingContractMethods from "../wallet/hooks/StakingContractsMethods";
import { useAllowancesReader } from "../wallet/hooks/Allowances";
import { useStakerInfoReader } from "../wallet/hooks/StakerInfo";
import BigNumber from "bignumber.js";
import useToggle from "../../../common/hooks/toggle";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import { formatBigNumber } from "../../../common/Amount";
import { useLoadingIndicatorReader } from "../wallet/hooks/LoadingIndicator";
import { hasPassed } from "../../../common/date/DateFormat";
import { useDerivedStakingInfoReader } from "../wallet/hooks/LatestBlockDetails";
import { usePool } from "../../../pools/Configs";
import UnstakeWarningDialog from "./UnstakeWarningDialog";
import StakeTokenLogo from "../../../common/StakeTokenLogo";

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  onClose: () => void;
}

export const CloseButton = (props: DialogTitleProps) => {
  const { onClose } = props;
  return (
    <IconButton
      aria-label="close"
      onClick={onClose}
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};

interface StakeDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

function StakingDialog({ onClose, isOpen }: StakeDialogProps) {
  const loadingIndicator = useLoadingIndicatorReader();
  const { stake } = useStakingContractMethods();
  const stakerInfo = useStakerInfoReader();
  const userTokenBalance = stakerInfo.balance.toNumber();
  const [stakingAmount, updateStakingAmount] = useState(userTokenBalance);
  const [isMax, setMax] = useState(true);
  const pool = usePool();

  const handleClose = () => {
    setMax(false);
    updateStakingAmount(0);
    onClose();
  };

  return (
    <BootstrapDialog onClose={handleClose} open={isOpen}>
      <CloseButton onClose={handleClose} />
      <DialogContent
        sx={{
          maxWidth: 500,
          minWidth: {
            xs: "auto",
            sm: 400,
          },
        }}
      >
        <Typography
          variant={"h6"}
          fontWeight={"bold"}
          fontSize={"medium"}
        >{`Stake ${pool.stakingToken.symbol}`}</Typography>
        <Typography
          color={"GrayText"}
          variant={"subtitle2"}
          fontWeight={"normal"}
          fontSize={"small"}
        >{`Your ${pool.stakingToken.symbol} will be staked.`}</Typography>
        <br />
        <Stack
          sx={{ pb: 1 }}
          direction={"row"}
          justifyContent={"space-between"}
        >
          <Typography
            color={"GrayText"}
            variant={"subtitle2"}
            fontWeight={"normal"}
            fontSize={"small"}
          >
            Balance
          </Typography>
          <Typography
            color={"GrayText"}
            variant={"subtitle2"}
            fontWeight={"normal"}
            fontSize={"small"}
          >
            {`Available ${formatBigNumber(stakerInfo.balance)} ${
              pool.stakingToken.symbol
            }`}
          </Typography>
        </Stack>
        <TextField
          value={stakingAmount}
          onChange={(e) => {
            setMax(false);
            updateStakingAmount(Number(e?.target?.value));
          }}
          fullWidth
          type={"tel"}
          InputProps={{
            endAdornment: (
              <Button
                variant={"text"}
                color={"primary"}
                onClick={() => {
                  setMax(true);
                  updateStakingAmount(userTokenBalance);
                }}
              >
                MAX
              </Button>
            ),
          }}
        />
        <br />
        <br />
        <LoadingButton
          loading={loadingIndicator.isStaking}
          disabled={stakingAmount > userTokenBalance || stakingAmount <= 0}
          variant={"contained"}
          fullWidth
          onClick={() => stake(stakingAmount, isMax, handleClose)}
        >
          Stake
        </LoadingButton>
      </DialogContent>
    </BootstrapDialog>
  );
}

const StakingCard = () => {
  const loadingIndicator = useLoadingIndicatorReader();
  const {
    open: openDialog,
    isOpen: isDialogOpen,
    close: closeDialog,
  } = useToggle();
  const { isConnected, openWalletConnectOptions } = useWalletConnect();
  const { getApproval, unstakeWithWarning } = useStakingContractMethods();
  const { hasAllowance } = useAllowancesReader();
  const stakerInfo = useStakerInfoReader();
  const { climeStartTime, isLoaded } = useDerivedStakingInfoReader();
  const pool = usePool();

  function StakingInfo() {
    return (
      <Stack justifyContent={"space-between"} alignItems="center">
        <Typography variant="h6" fontWeight={"bold"}>
          {`${formatBigNumber(stakerInfo.stakedAmount)} ${
            pool.stakingToken.symbol
          } Staked`}
        </Typography>
        <Typography variant="body2" color="text.disabled">
          {`Available: ${formatBigNumber(stakerInfo.balance)} ${
            pool.stakingToken.symbol
          }`}
        </Typography>
      </Stack>
    );
  }

  function ApprovalRequest() {
    return (
      <Stack justifyContent={"space-between"} alignItems="center">
        <Typography variant="h6" fontWeight={"bold"}>
          Approve {pool.stakingToken.symbol}
        </Typography>
      </Stack>
    );
  }

  function ConnectWalletRequest() {
    return (
      <Stack justifyContent={"space-between"} alignItems="center">
        <Typography variant="body2" color="text.disabled">
          Connect with your wallet to stake {pool.stakingToken.symbol} token and
          earn reward
        </Typography>
      </Stack>
    );
  }

  if (!isConnected) {
    return (
      <Stack justifyContent={"center"} alignItems="center" spacing={5}>
        <StakeTokenLogo type={pool.type} />
        <ConnectWalletRequest />
        <Button
          variant={"contained"}
          fullWidth
          size={"large"}
          onClick={openWalletConnectOptions}
        >
          Connect
        </Button>
      </Stack>
    );
  }

  if (pool.completed) {
    return (
      <Stack justifyContent={"center"} alignItems="center" spacing={5}>
        <img src={unmarshalLogo} alt="Unmarshal" width={140} />
        <StakingInfo />
        <Box width={"100%"} textAlign={"center"}>
          <LoadingButton
            sx={{ minWidth: 150 }}
            variant={"contained"}
            loading={loadingIndicator.isUnstaking}
            disabled={!stakerInfo.stakedAmount.gt(new BigNumber(0))}
            loadingPosition={"end"}
            size={"large"}
            onClick={unstakeWithWarning}
          >
            Withdraw
          </LoadingButton>
          {<Box height={50} />}
        </Box>
      </Stack>
    );
  }

  if (!hasAllowance) {
    return (
      <Stack justifyContent={"center"} alignItems="center" spacing={5}>
        <StakeTokenLogo type={pool.type} />
        <ApprovalRequest />
        <LoadingButton
          loading={loadingIndicator.isApproving}
          variant={"contained"}
          fullWidth
          size={"large"}
          onClick={getApproval}
        >
          Approve
        </LoadingButton>
      </Stack>
    );
  }
  const isClaimable = hasPassed(climeStartTime);
  return (
    <Stack justifyContent={"center"} alignItems="center" spacing={5}>
      <StakeTokenLogo type={pool.type} />
      <StakingInfo />
      <Box width={"100%"} textAlign={"center"}>
        <Button
          variant={"contained"}
          fullWidth
          size={"large"}
          onClick={openDialog}
        >
          Stake
        </Button>
        <LoadingButton
          sx={{ minWidth: 150 }}
          loading={loadingIndicator.isUnstaking}
          disabled={!stakerInfo.stakedAmount.gt(new BigNumber(0))}
          loadingPosition={"end"}
          size={"large"}
          onClick={unstakeWithWarning}
        >
          Unstake
        </LoadingButton>
        {!isClaimable && isLoaded && <Box height={50} />}
      </Box>
      <StakingDialog onClose={closeDialog} isOpen={isDialogOpen} />
      <UnstakeWarningDialog />
    </Stack>
  );
};

export default StakingCard;
