import React, { Fragment, useEffect } from "react";
import { useWalletConnect } from "./hooks/Connections";

const AutoWalletConnect = () => {
  const { tryAutoConnect } = useWalletConnect();

  useEffect(tryAutoConnect, []);

  return <Fragment />;
};

export default AutoWalletConnect;
