import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { atom, useRecoilState } from "recoil";
import Typography from "@mui/material/Typography";
import {
  Divider,
  Link,
  List,
  ListItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import IconButton from "@mui/material/IconButton";
import metamaskLogo from "../../../../assets/images/metamaskLogo.svg";
import binanceLogo from "../../../../assets/images/binanceLogo.svg";
import walletConnectLogo from "../../../../assets/images/walletConnectLogo.svg";
import trustWalletLogo from "../../../../assets/images/trustWalletLogo.svg";
import Button from "@mui/material/Button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import { useWalletConnect } from "./hooks/Connections";
import { Wallets } from "./hooks/Connectors";
import { CloseButton } from "../stake/StakingCard";

function WalletsList() {
  const { connectWallet } = useWalletConnect();
  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <ListItem
        component={Button}
        onClick={() => connectWallet(Wallets.Metamask)}
        secondaryAction={
          <IconButton edge="end" aria-label="delete">
            <KeyboardArrowRightIcon />
          </IconButton>
        }
      >
        <ListItemAvatar>
          <img src={metamaskLogo} alt="" />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography color="text.primary" variant="h6" fontWeight={"bold"}>
              Metamask
            </Typography>
          }
          secondary={
            <Typography
              color="text.disabled"
              fontWeight={"bold"}
              sx={{ opacity: "70%" }}
            >
              Connect using browser wallet
            </Typography>
          }
        />
      </ListItem>
      <ListItem
        component={Button}
        onClick={() => connectWallet(Wallets.BscChain)}
        secondaryAction={
          <IconButton edge="end" aria-label="delete">
            <KeyboardArrowRightIcon />
          </IconButton>
        }
      >
        <ListItemAvatar>
          <img src={binanceLogo} alt="" />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography color="text.primary" variant="h6" fontWeight={"bold"}>
              Binance Chain
            </Typography>
          }
          secondary={
            <Typography
              color="text.disabled"
              fontWeight={"bold"}
              sx={{ opacity: "70%" }}
            >
              Connect using browser wallet
            </Typography>
          }
        />
      </ListItem>
      <ListItem
        component={Button}
        onClick={() => connectWallet(Wallets.Metamask)}
        secondaryAction={
          <IconButton edge="end" aria-label="delete">
            <KeyboardArrowRightIcon />
          </IconButton>
        }
      >
        <ListItemAvatar>
          <img src={trustWalletLogo} alt="" width={40} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography color="text.primary" variant="h6" fontWeight={"bold"}>
              Trust Wallet
            </Typography>
          }
          secondary={
            <Typography
              color="text.disabled"
              fontWeight={"bold"}
              sx={{ opacity: "70%" }}
            >
              Connect using Trust Wallet
            </Typography>
          }
        />
      </ListItem>
      <ListItem
        component={Button}
        onClick={() => connectWallet(Wallets.WalletConnect)}
        secondaryAction={
          <IconButton edge="end" aria-label="delete">
            <KeyboardArrowRightIcon />
          </IconButton>
        }
      >
        <ListItemAvatar>
          <img src={walletConnectLogo} alt="" width={40} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography color="text.primary" variant="h6" fontWeight={"bold"}>
              Wallet Connect
            </Typography>
          }
          secondary={
            <Typography
              color="text.disabled"
              fontWeight={"bold"}
              sx={{ opacity: "70%" }}
            >
              Connect using Wallet Connect
            </Typography>
          }
        />
      </ListItem>
    </List>
  );
}

const IS_WALLETS_DIALOG_OPEN = "IS_WALLETS_DIALOG_OPEN";

const userMenuOpenState = atom<boolean>({
  key: IS_WALLETS_DIALOG_OPEN,
  default: false,
});

export const useWalletOptionsDialog = () => {
  const [isOpen, updateIsOpen] = useRecoilState(userMenuOpenState);
  return {
    isOpen,
    toggleMenu: () => updateIsOpen(!isOpen),
    closeDialog: () => updateIsOpen(false),
  };
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function WalletOptionsDialog() {
  const { isOpen, closeDialog } = useWalletOptionsDialog();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth={"xs"}
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
    >
      <CloseButton onClose={closeDialog} />
      <DialogTitle>
        <Typography variant="h5" fontWeight="bolder" textAlign={"center"}>
          Login
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Card variant={"outlined"}>
          <CardContent>
            <WalletsList />
          </CardContent>
        </Card>
        <Divider sx={{ my: 2 }} />
        <Typography textAlign={"center"}>
          Dont have wallet download?{" "}
          <Link target="_blank" href="https://metamask.io/download.html">
            Download here
          </Link>
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
