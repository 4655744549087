import React, { Fragment } from "react";
import { default as WalletOptionsDialogV2 } from "./stakingWithVariableAPRV2/bockchain/wallet/WalletOptionsDialog";
import WalletOptionsDialog from "./stakingWithVariableAPR/bockchain/wallet/WalletOptionsDialog";
import { default as UnstakeWarningDialogV2 } from "./stakingWithVariableAPRV2/bockchain/stake/UnstakeWarningDialog";
import UnstakeWarningDialog from "./stakingWithVariableAPR/bockchain/stake/UnstakeWarningDialog";

const DialogContainer = () => {
  return (
    <Fragment>
      <WalletOptionsDialog />
      <WalletOptionsDialogV2 />
    </Fragment>
  );
};

export default DialogContainer;
