import React from "react";
import Stack from "@mui/material/Stack";
import Footer from "../Footer";
import ListingContainer from "./ListingContainer";
import AppBar from "../AppBar";

const Landing = () => {
  return (
    <Stack minHeight={"100vh"} justifyContent={"space-between"} spacing={2}>
      <Stack>
        <AppBar />
        <ListingContainer />
      </Stack>
      <Footer />
    </Stack>
  );
};

export default Landing;
