import { atom, useRecoilState, useResetRecoilState } from "recoil";
import DefaultAPIDataValues, { APIData } from "../common/rest-client/types";
import useGetRequest from "../common/rest-client/get";
import { Token, TokenTypes } from "../../Types";
import { TokenPrice } from "./PriceStoreClient";
import { CoingeckoPrice } from "./PriceDetailsClient";

export const rewardTokenPriceAtom = atom<APIData<number>>({
  key: "REWARD_TOKEN_PRICE_ATOM",
  default: DefaultAPIDataValues,
});

const useRewardTokenPriceClient = () => {
  const [price, updatePriceDetails] = useRecoilState(rewardTokenPriceAtom);
  const reset = useResetRecoilState(rewardTokenPriceAtom);
  const get = useGetRequest<number>();

  const getPriceDetails = (token: Token) =>
    token.type == TokenTypes.LP
      ? get(
          updatePriceDetails,
          `/v1/pricestore/chain/bsc/lptokens?lptokens=${token.address}`,
          {
            parse: (data: Array<TokenPrice>) =>
              data?.length === 1 && data[0].price,
          }
        )
      : get(
          updatePriceDetails,
          "https://api.coingecko.com/api/v3/simple/price?ids=binancecoin%2Cunmarshal&vs_currencies=usd",
          {
            parse: (data: CoingeckoPrice) => data.unmarshal.usd,
          }
        );

  return { price, getPriceDetails, reset };
};

export default useRewardTokenPriceClient;
